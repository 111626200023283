<template>
  <div>
    <retreatIndex :type="2" :list="list" />
  </div>
</template>

<script>
import retreatIndex from './components/index.vue';
import { reactive, toRefs, onMounted } from 'vue';

export default {
  components: {
    retreatIndex
  },
  setup() {
    const state = reactive({
      list: []
    });
    onMounted(async () => {
      state.list = [
        { name: '《仙武帝尊》叶xx辰', list: [4, 2] },
        { name: '《仙武帝尊》姬凝霜', list: [1, 4, 3] }
      ];
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped></style>
